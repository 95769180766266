import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import WithLayout from './WithLayout';
import {
  Main as MainLayout
} from './layouts';

import {
  NotFound as NotFoundView,
} from './views-template/supportingPages';

import 'assets/styles/normalize.css';
import 'assets/styles/index.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import Index from 'views/Index';

const Routes = () => {
  return (
    <Switch>
      <Route path="/" exact render={(matchProps) => (
        <WithLayout
          {...matchProps}
          component={Index}
          layout={MainLayout}
        />
      )} />

      <Route path="/page-not-found" exact
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={NotFoundView}
            layout={MainLayout}
          />
        )}
      />

      <Redirect to="/page-not-found" />
    </Switch>
  );
};

export default Routes;
