import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
// import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import DawntechLogo from '../../../../svg/logos/Dawntech';
import LanguageIcon from '@mui/icons-material/Language';
import { useTranslation } from 'react-i18next';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import FlagIcon from '../../../../svg/flags';

const Topbar = ({
  themeMode,
  themeToggler,
}) => {
  const { t, i18n } = useTranslation();
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const languageMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const languageMenuClose = () => {
    setAnchorEl(null);
  };
  const selectLanguage = (lang) => {
    return i18n.changeLanguage(lang, () => {
      languageMenuClose();
    });
  };

  const languages = i18n.options.supportedLngs;

  const ciIndex = languages.findIndex(l => l === 'cimode');
  if (ciIndex !== -1) {
    languages.splice(ciIndex, 1);
  }

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={'100%'}
    >
      <Box display={'flex'} alignItems={'center'}>
        <Box
          display={'flex'}
          alignItems="baseline"
          component="a"
          underline="none"
          href="/"
          title={t('catalyst')}
          height={{ xs: 28, md: 32 }}
          width={116}
        >
          <img src={ themeMode === 'light' ? DawntechLogo.cns : DawntechLogo.bns } alt={t('catalyst')} />
        </Box>
      </Box>
      <Box display="flex" alignItems={'center'}>

        <Box>
          <IconButton
            onClick={() => themeToggler()}
            aria-label="Dark mode toggler"
            color={themeMode === 'light' ? 'primary' : 'secondary'}
          >
            {themeMode === 'light' ? (
              <svg
                width={24}
                height={24}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                />
              </svg>
            ) : (
              <svg
                width={24}
                height={24}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
                />
              </svg>
            )}
          </IconButton>
          <IconButton
            onClick={languageMenuOpen}
            aria-label="Language toggler"
            color={themeMode === 'light' ? 'primary' : 'secondary'}
          >
            <LanguageIcon></LanguageIcon>
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={languageMenuClose}
          >
            {
              languages.map((lang,i) => (
                <MenuItem onClick={() => selectLanguage(lang)} key={i} selected={i18n.resolvedLanguage === lang}>
                  <FlagIcon flag={lang}></FlagIcon>
                  <Typography pl={1}>
                    {t(`languages.${lang}`)}
                  </Typography>
                </MenuItem>
              ))
            }
          </Menu>
        </Box>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  themeToggler: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
  setThemePalette: PropTypes.func.isRequired,
  paletteType: PropTypes.string.isRequired,
};

export default Topbar;
