import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { useTranslation } from 'react-i18next';


const Pricing = () => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  let initialPlanName = 'Basic';
  const [selectedPlan, setPlan] = useState(t('pricing.plans', { returnObjects: true }).find(plan => plan.name === initialPlanName));

  const handleClick = (event, newPlan) => {
    if (newPlan) {
      setPlan(newPlan);
    }
  };

  const handleLanguageChanged = useCallback(() => {
    const newPlan = i18n.t('pricing.plans', { returnObjects: true }).find(plan => plan.name === initialPlanName);
    setPlan(newPlan);
  }, []);

  useEffect(() => {
    i18n.on('languageChanged', handleLanguageChanged);
  }, []);

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'secondary'}
          align={'center'}
        >
          {t('pricing.legend')}
        </Typography>
        <Box
          component={Typography}
          fontWeight={700}
          variant={'h3'}
          align={'center'}
        >
          {t('pricing.title')}
        </Box>
      </Box>
      <Grid container spacing={isMd ? 0 : 2}>
        <Grid item xs={12} md={6}>
          <Card data-aos={isMd ? 'fade-right' : 'fade-up'}>
            <Box component={CardContent} padding={{ sm: 4 }}>
              <Box display={'flex'} justifyContent={'center'} marginBottom={4}>
                <ToggleButtonGroup
                  value={selectedPlan}
                  exclusive
                  onChange={handleClick}
                >
                  {
                    t('pricing.plans', { returnObjects: true }).map((plan, i) => (
                      <ToggleButton
                        key={i}
                        value={plan}
                        size={'small'}
                        sx={{
                          backgroundColor: selectedPlan.name === plan.name ? `${theme.palette.primary.light} !important` : 'transparent',
                          border: `1px solid ${theme.palette.primary.main}`,
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{
                            fontWeight: 'medium',
                            color:
                              (selectedPlan.name === plan.name) || (selectedPlan.name == '')
                                ? theme.palette.common.white
                                : 'primary',
                          }}
                        >
                          { plan.name }
                        </Typography>
                      </ToggleButton>
                    ))
                  }
                </ToggleButtonGroup>
              </Box>
              <Box marginBottom={4}>
                <Typography
                  fontWeight={600}
                  variant={'h2'}
                  align={'center'}
                  gutterBottom
                >
                 R${selectedPlan.value}
                </Typography>
                <Typography color="text.secondary" align={'center'}>
                  {selectedPlan.description}
                </Typography>
              </Box>
              <Grid container spacing={1}>
                {
                  selectedPlan.items.map((item, j) => (
                    <Grid item xs={12} sm={6} key={j}>
                      <Box
                        component={ListItem}
                        disableGutters
                        width={'auto'}
                        padding={0}
                      >
                        <Box
                          component={ListItemAvatar}
                          minWidth={'auto !important'}
                          marginRight={2}
                        >
                          <Box
                            component={Avatar}
                            bgcolor={theme.palette.secondary.main}
                            width={20}
                            height={20}
                          >
                            <svg
                              width={12}
                              height={12}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </Box>
                        </Box>
                        <ListItemText primary={item} />
                      </Box>
                    </Grid>
                  ))
                }
              </Grid>
            </Box>
            <Divider />
            <Box component={CardActions} justifyContent={'center'}>
              <Button 
                href={selectedPlan.link}
                target="_blank"
                rel="noopener noreferrer"
                size={'large'}
              >
                {selectedPlan.action}
              </Button>
            </Box>
          </Card>
        </Grid>
        <Grid item container xs={12} md={6} alignItems={'center'}>
          <Box
            component={Card}
            bgcolor={theme.palette.primary.main}
            data-aos={isMd ? 'fade-left' : 'fade-up'}
          >
            <Box
              component={CardContent}
              padding={{ sm: 4 }}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
            >
              <Box color={theme.palette.common.white} marginBottom={4}>
                <svg
                  width={80}
                  height={80}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M10 3.5a1.5 1.5 0 013 0V4a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-.5a1.5 1.5 0 000 3h.5a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-.5a1.5 1.5 0 00-3 0v.5a1 1 0 01-1 1H6a1 1 0 01-1-1v-3a1 1 0 00-1-1h-.5a1.5 1.5 0 010-3H4a1 1 0 001-1V6a1 1 0 011-1h3a1 1 0 001-1v-.5z" />
                </svg>
              </Box>
              <Box
                component={Typography}
                variant={'h4'}
                color={theme.palette.common.white}
                fontWeight={600}
                gutterBottom
              >
                {t('pricing.custom.name')}
              </Box>
              {
                t('pricing.custom.lines', { returnObjects: true }).map((line, i) => (
                  <Box
                    key={i}
                    component={Typography}
                    color={theme.palette.common.white}
                    gutterBottom
                    align={'center'}
                  >
                    {line}
                  </Box>
                ))
              }
            </Box>
            <Divider />
            <Box component={CardActions} justifyContent={'center'}>
              <Box
                component={Button}
                color={theme.palette.common.white}
                size={'large'}
                href={t('pricing.custom.link')}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('pricing.custom.action')}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Pricing;
