import client_1 from '../../../../assets/img/logos/clients/1.png';
import client_2 from '../../../../assets/img/logos/clients/2.png';
import client_3 from '../../../../assets/img/logos/clients/3.png';
import client_4 from '../../../../assets/img/logos/clients/4.png';
import client_5 from '../../../../assets/img/logos/clients/5.png';
import client_7 from '../../../../assets/img/logos/clients/7.png';
import client_8 from '../../../../assets/img/logos/clients/8.png';
import client_9 from '../../../../assets/img/logos/clients/9.png';
import client_10 from '../../../../assets/img/logos/clients/10.png';

export default [
  { src: client_1, href: 'https://eaquamar.com.br/' },
  { src: client_2, href: 'https://www.bagaggio.com.br/' },
  { src: client_3, href: 'https://bibi.com/' },
  { src: client_4, href: 'https://www.drogasmil.com.br/' },
  { src: client_5, href: 'https://www.dinnertwist.com.au/' },
  { src: client_7, href: 'https://www.lindacasa.com.br' },
  { src: client_8, href: 'https://www.ls2.com.br' },
  { src: client_9, href: 'https://www.overcome.com.br/' },
  { src: client_10, href: 'https://www.relaxmedic.com.br/' }
];
